import React, {Component, Suspense, lazy} from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import './App.css';
import SiteHeader from '../../components/Header/Header';
import CompanyHeader from "../CompanyHeader/CompanyHeader";
import SiteFooter from '../../components/Footer/Footer';
import HomePage from '../HomePage/HomePage';
const HouseRentalPage = lazy(() => import("../HouseRentalPage/HouseRentalPage"));
const ApplyCareGiverPage = lazy(() => import("../ApplyCareGiverPage/ApplyCareGiverPage"));
// import AboutPage from '../../components/PageContent/AboutPage/AboutPage';
const SoftwarePage = lazy(() => import('../../components/PageContent/SoftwarePage/SoftwarePage'));
const AuditingPage = lazy(() => import('../../components/PageContent/AuditingPage/AuditingPage'));
const TrainingPage = lazy(() => import('../../components/PageContent/TrainingPage/TrainingPage'));
const PublicationsPage = lazy(() => import('../../components/PageContent/PublicationsPage/PublicationsPage'));
const ContactPage = lazy(() => import('../ContactPage/ContactPage'));

const sea2riverPages = [
    "software",
    "auditing",
    "training",
    "publications",
    "contact"
];

class App extends Component {
    state = {
        isAuthenticated: false,
        isAuthenticating: false,
        user: null,
        permissions: null,
        module_templates: {},
        moduleTemplatesLoaded: false,
        loginRedirect: "",
        companyHeaderVisibility: true,
        dueDateLists: [],
        dueDateAttachmentsToSend: [],
        dueDateAttachmentsToEdit: [],
        dueDateAttachmentsToDelete: [],
        seaTimeVessels: [],
        seaTimeEmployeePunches: [],
        vesselsData: [],
        formManagementCompanyTemplates: [],
        formManagementEmployeeData: [],
        formManagementSubmissionData: []
    }

    updateCompanyHeaderVisibilityHandler = async (visibility) => {
        await this.setState({companyHeaderVisibility: visibility});
    };

    render() {
        const authState = {
            isAuthenticated: this.state.isAuthenticated,
            user: this.state.user,
            permissions: this.state.permissions,
            loginRedirect: this.state.loginRedirect,
            module_templates: this.state.module_templates,
            moduleTemplatesLoaded: this.state.moduleTemplatesLoaded,
            getTemplates: this.getTemplates,
            updateIsAuthenticatedHandler: this.updateIsAuthenticatedHandler,
            updateUserHandler: this.updateUserHandler,
            updatePermissionsHandler: this.updatePermissionsHandler,
            updateModuleTemplatesHandler: this.updateModuleTemplatesHandler,
            updateLoginRedirectHandler: this.updateLoginRedirectHandler
        };

        let siteHeader = null;
        let siteFooter = null;

        if (this.props.location.pathname.split("/")[1]) {
            if (sea2riverPages.includes(this.props.location.pathname.split("/")[1].toLocaleLowerCase())) {
                siteHeader = <SiteHeader {...this.props} auth={authState}/>;
                siteFooter = <SiteFooter/>;
            } else {
                if (this.state.companyHeaderVisibility === true) {
                    siteHeader = <CompanyHeader {...this.props} auth={authState}/>;
                }
                siteFooter = null;
            }
        } else {
            siteHeader = <SiteHeader {...this.props} auth={authState}/>;
            siteFooter = <SiteFooter/>;
        }

        if (this.state.permissions && this.state.isAuthenticating === false) {

        }

        return (
            <div className="App">
                {this.state.isAuthenticating === false ?
                    <Suspense fallback={<h1>Welcome! We're loading things up for you!</h1>}>
                        {siteHeader}
                        {this.state.user === null ?
                            <Switch>
                                <Route path="/rental"
                                       render={(props) =>
                                           <HouseRentalPage {...props}
                                                            auth={authState}
                                                            updateCompanyHeaderVisibilityHandler={this.updateCompanyHeaderVisibilityHandler}/>}/>
                                <Route path="/applycaregiver"
                                       render={(props) =>
                                           <ApplyCareGiverPage {...props}
                                                               auth={authState}
                                                               updateCompanyHeaderVisibilityHandler={this.updateCompanyHeaderVisibilityHandler}/>}/>
                                <Route path="/software"
                                       render={(props) =>
                                           <SoftwarePage {...props} auth={authState}/>}/>
                                <Route path="/auditing"
                                       render={(props) =>
                                           <AuditingPage {...props} auth={authState}/>}/>
                                <Route path="/training"
                                       render={(props) =>
                                           <TrainingPage {...props} auth={authState}/>}/>
                                <Route path="/publications"
                                       render={(props) =>
                                           <PublicationsPage {...props} auth={authState}/>}/>
                                <Route path="/contact"
                                       render={(props) =>
                                           <ContactPage {...props} auth={authState}/>}/>
                                <Route path="/"
                                       exact
                                       render={(props) =>
                                           <HomePage {...props} auth={authState}/>}/>
                                <Redirect to="/login"/>
                            </Switch>
                            :
                            null
                        }
                        {siteFooter}
                    </Suspense>
                    : <h1>Welcome! We're loading things up for you!</h1>}
            </div>
        );
    }
}

export default withRouter(App);
