import React, {Component} from 'react';
import classes from './CompanyHeader.module.css';

class CompanyHeader extends Component {
    state = {
        company_link: window.location.href.split("/")[3],
        apps: [],
        appLinksDisplay: "none",
        mobileLinksVisible: [classes.mobileLinkContainer, classes.notVisible]
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated && this.props.auth.user) {
            for (let i = 0; i < this.props.auth.permissions.length; i++) {
                if (this.state.company_link === this.props.auth.permissions[i].company_link) {
                    this.setState({
                        apps: this.props.auth.permissions[i].permissions
                    });
                    break;
                }
            }
        }
    };

    mobileLinkVisibleHandler = () => {
        this.state.mobileLinksVisible.includes(classes.notVisible) ?
            this.setState({mobileLinksVisible: [classes.mobileLinkContainer, classes.isVisible]}) :
            this.setState({mobileLinksVisible: [classes.mobileLinkContainer, classes.notVisible]});
    };

    render() {

        return (
            <header className={classes.companyHeader}>
                {this.props.auth.isAuthenticated && this.props.auth.user ?
                    <div>
                        <span className={classes.menuButtonContainer} onClick={this.mobileLinkVisibleHandler}>
            MENU <span className={classes.menuButton}>
                        <div className={classes.menuLine}></div>
                        <div className={classes.menuLine}></div>
                        <div className={classes.menuLine}></div>
                    </span>
                </span>
                    </div> : null}
            </header>
        );
    }
}

export default CompanyHeader;