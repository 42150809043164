import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import classes from './Header.module.css';
import NavButton from './NavButton/NavButton';

class siteHeader extends Component {
    state = {
        appLinksDisplay: "none",
        mobileLinksVisible: [classes.mobileLinkContainer, classes.notVisible]
    }

    appLinksDisplayHandler = () => {
        this.state.appLinksDisplay === "none" ?
            this.setState({appLinksDisplay: "block"}) :
            this.setState({appLinksDisplay: "none"});
    };

    mobileLinkVisibleHandler = () => {
        this.state.mobileLinksVisible.includes(classes.notVisible) ?
            this.setState({mobileLinksVisible: [classes.mobileLinkContainer, classes.isVisible]}) :
            this.setState({mobileLinksVisible: [classes.mobileLinkContainer, classes.notVisible]});
    };

    linkCLickedHandler = () => {
        this.setState({mobileLinksVisible: [classes.mobileLinkContainer, classes.notVisible]});
    };

    render() {

        return (
            <header className={classes.siteHeader}>
                <span className={classes.companyName}><NavButton
                    destination="/">Sea<b><i>2</i></b>River</NavButton>
                </span>
                <span className={classes.menuButtonContainer} onClick={this.mobileLinkVisibleHandler}>
            MENU <span className={classes.menuButton}>
                        <div className={classes.menuLine}></div>
                        <div className={classes.menuLine}></div>
                        <div className={classes.menuLine}></div>
                    </span>
                </span>
                <span className={classes.desktopLinkContainer}>
                    <NavButton destination="/Software">Software</NavButton>
                    <NavButton destination="/Training">Training</NavButton>
                    <NavButton destination="/Auditing">Auditing / Surveying</NavButton>
                    <NavButton destination="/Publications">Publications</NavButton>
                    <NavButton destination="/Contact">Contact</NavButton>
                    {this.props.auth.isAuthenticated && this.props.auth.user ?
                        <span className={classes.companyLinkContainer}>
                            <span onClick={this.appLinksDisplayHandler}
                                  className={classes.companyContainerToggleButton}>Your Apps</span>
                            <div style={{"display": this.state.appLinksDisplay}}>
                                <div className={classes.companyLink} style={{"top": "20px"}}>
                                    <NavLink to="/companies" activeClassName={classes.active}>Your Work Area</NavLink>
                                </div>
                                <div className={classes.companyLink} style={{"top": "75px"}}>
                                    <NavLink to="/newcompany" activeClassName={classes.active}>Create a New Company Profile</NavLink>
                                </div>
                            </div>
                        </span> : null}
                </span>
                <span className={this.state.mobileLinksVisible.join(" ")}
                      onClick={(event) => this.linkCLickedHandler(event)}>
                    <div className={classes.MobileLinkSeparator}>Your Apps</div>
                    <div className={classes.MobileLinkSeparator}>Sea2River Products & Services</div>
                    <NavButton destination="/Software">Browse Software Catalog</NavButton>
                    <NavButton destination="/Training">Request Training Services</NavButton>
                    <NavButton destination="/Auditing">Auditing / Surveying</NavButton>
                    <NavButton destination="/Publications">Publications</NavButton>
                    <NavButton destination="/Contact">Contact</NavButton>
                </span>
            </header>
        );
    }
}

export default siteHeader;