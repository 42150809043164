import React, {Component} from 'react';
import classes from './HomePage.module.css';

// import AboutImage from '../../assets/About.jpg';
// import SoftwareImage from '../../assets/Software.jpg';
// import AuditingImage from '../../assets/Auditing.jpg';
// import TrainingImage from '../../assets/Training.jpg';
// import PublicationsImage from '../../assets/Publications.jpg';

class HomePage extends Component {
    // Base carousel logic courtesy of https://codepen.io/nbondy/pen/rwWBqx?editors=0110
    // state = {
    //     currentIndex: 0
    // };
    //
    // shiftCarouselRight = () => {
    //     this.state.currentIndex < 4 ?
    //         this.setState({currentIndex: this.state.currentIndex + 1}) : this.setState({currentIndex: 0});
    // };
    //
    // shiftCarouselLeft = () => {
    //     this.state.currentIndex > 0 ?
    //         this.setState({currentIndex: this.state.currentIndex - 1}) : this.setState({currentIndex: 4});
    // };

    render() {
        // let carouselStyle = {
        //     transform: `translateX(${(this.state.currentIndex * -100)/5}%)`,
        //     transition: `0.3s`
        // };
        //
        // let carousel = <div className={classes.Carousel}>
        //     <button className={classes.CarouselButtonLeft} onClick={this.shiftCarouselLeft}>◀</button>
        //     <button className={classes.CarouselButtonRight} onClick={this.shiftCarouselRight}>▶</button>
        //     <ol className={classes.CarouselSlider} style={carouselStyle}>
        //         <li className={classes.CarouselSlide} key={0}><img src={AboutImage} /></li>
        //         <li className={classes.CarouselSlide} key={1}><img src={SoftwareImage} /></li>
        //         <li className={classes.CarouselSlide} key={2}><img src={AuditingImage} /></li>
        //         <li className={classes.CarouselSlide} key={3}><img src={TrainingImage} /></li>
        //         <li className={classes.CarouselSlide} key={4}><img src={PublicationsImage} /></li>
        //     </ol>
        // </div>;

        return (
            <div className={classes.ContentContainer}>

                <div className={classes.Content}>
                    <h1><b style={{"color": "red"}}>No Harm</b> to People, Property, & Planet</h1>
                    <h3>
                        <b style={{"color": "red"}}>Our Mission</b> is to provide quality service and value while helping our customers achieve No
                        Harm to People, Property and Planet through a belief that all accidents are preventable and
                        reducing risk to as low as reasonably practicable.
                    </h3>
                    <div className={classes.Offerings}>
                        <h2>We Offer:</h2>
                        <div>
                            <table>
                                <thead>
                                <tr>
                                    <th>SOFTWARE</th>
                                    <th>TRAINING & AUDITING/SURVEYING</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Mobile Apps</td>
                                    <td>Live Streaming Safety Training / Messages</td>
                                </tr>
                                <tr>
                                    <td>Near Miss, Stop Work, Hazard Hunter, and Behavior Based Safety (BBS) on mobile devices</td>
                                    <td>Video Safety Training on mobile devices</td>
                                </tr>
                                <tr>
                                    <td>Audits, Surveys & Inspections on mobile devices</td>
                                    <td>Subchapter M Auditing</td>
                                </tr>
                                <tr>
                                    <td>Certificate, Equipment & Audit Due Date Alerting</td>
                                    <td>Subchapter M Surveying</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Subchapter M Vessel Logbooks</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomePage;