import React from 'react';
import {NavLink} from 'react-router-dom';
import classes from './Footer.module.css';

const siteFooter = () => {
    return (
        <div className={classes.SiteFooter}>
            <span className={classes.Links}>
                <NavLink to="/" exact>Home</NavLink>
                <NavLink to="/software">Software</NavLink>
                <NavLink to="/training">Training</NavLink>
                <NavLink to="/auditing">Auditing / Surveying</NavLink>
                <NavLink to="/publications">Publications</NavLink>
                <NavLink to="/contact">Contact</NavLink>
            </span>
            <span className={classes.Copyright}>Copyright Sea2River 2020</span>
        </div>
    );
};

export default siteFooter;