import React from 'react';
import {NavLink} from 'react-router-dom';
import classes from './NavButton.module.css';

const navButton = (props) => {
    return (<span className={classes.NavButton}>
        <NavLink to={props.destination} exact={props.exact} activeClassName={classes.active}>{props.children}</NavLink>
    </span>);
};

export default navButton;